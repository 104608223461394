import React from 'react'
import { Link } from "gatsby"

import * as styles from '../styles/profile.module.scss'

import Layout from "../components/layout"

const Profile = ({location})=>{
    return(
        <Layout location = {location} title = "profile">
            <div className = {styles.profilewrapper}>
                <h1>プロフィール</h1>

                <h2>どーじょーとバイクについて</h2>
                <p>１９８５年５月１６日生まれ。仕事は１０年以上教員をしていましたが、色々あって２０２１年度をもって退職。プログラミング学習中。趣味は美味しいものを食べること、スポーツ（バレーボールが得意）などなど。</p>
                <p>このブログでは、バイクで地元大分を回りながら、バイクと大分の魅力を伝えていきたいと思います。</p>

                <h3>そもそも二輪免許は車を守るためにとりました</h3>
                    <p>当時、Jeepのレネゲードがちょうど新規で発売された頃で「これはカッコイイ！」と惚れて新車で買ってしまいました。</p>
                    <p>しかし、その直後に転勤。しかも片道６０km、という遠方に転勤することになりました。</p>
                    <p>ですが、引っ越すつもりがなかったので通うことにしました。</p>
                    <p>「せっかく新車で買ったのに…」と思いながら、「とりあえずこの車で通うかぁ…」ということで、１年間通いましたがなんと走行距離…</p>

                    <p>１年間で４万キロ</p>
                    
                    <p>さすがに「ちょっと待てよ！すぐ車が潰れる！」と思い、どうしたもんか、と対策を練ることにしました。</p>
                    <p>そこで思い付いたのが、「バイクで通う、というのは？」というものでした。</p>
                
                <h3>バイクはコスパがいいぞ！</h3>
                    <p>調べてみると、「バイクの２５０ccはとてもコスパがよい」ということがわかりました。</p>
                    <p>車検なし、燃費も良い、高速道路にものれる（当時は高速通勤でした）、本体価格も比較的良心的。</p>
                    <p>その頃同僚がバイクに乗ってたのを見て、カッコいいなぁ（多分YZF-R1だったと思う）と思ったのも手伝いました。</p>
                    <p>ということで、仕事の合間に教習所に通い、３１歳にして自動二輪の免許を取得しました。</p>
                    <p>教習所に通うのは遠距離通勤の合間だったので、結構大変でしたね。でもまぁ、人間その気になれば大体のことはできます。</p>
                
                <h3>愛車GSX250Rを購入</h3>
                    <p>このあたりはもう一つのブログに書いてありますので、そちらもご覧いただければ、と思います。</p>
                    <p>納車されたときは「おぉ、これが自分のバイクか！公道で乗れるのか？」と、ちょっとビビりましたね。</p>
                    <p>もっとビビったのは「車両代は現金で払ってね」と言われたことです。車をローンでしか買ったことがなかったので、そういえば支払いどうするんだろ？とは思っていたのですが…確認しろよ、って話ですね。急いで銀行に行っておろしてきましたよ。２５０ccくらいならキャッシュで買うのが常識なのだろうか…</p>
                    <p>ともあれ、無事に？納車されたので、「とりあえず乗ってみるかぁ」という感じで恐る恐る公道へ。</p>
                
                <h3>いざ！バイク通勤！</h3>
                    <p>まずは慣らし運転で１０００km程ぐるぐると県内を走り回りました。初めて公道に出て６０km/h出したときは「風圧すげぇ！」と思ったのを覚えています。ハッキリ言って怖かったですね。</p>
                    <p>そして、慣らしたあとに、高速道路に乗ってみました。恐怖体験でしたね。「コレ毎日とか無理！」と思いました。</p>
                    <p>しかし、ここで諦めたら本末転倒なので「車を守るため」とバイク通勤を始めました。</p>
                    <p>最初の数週間は疲労がハンパではなかったですね。バイクで高速通勤の後仕事→バイクで帰る、というのはかなり体力を消耗しました。しかも、そもそも６０kmの遠距離通勤なので、疲労は溜まります。</p>
                    <p>が、しばらくすると慣れてきました。高速道路での恐怖心も無くなり、体力的にも割と平気になりました。今思うと、最初はガチガチに力が入ってたのかな、というところですかね。とにかく、人間慣れれば大概のことは平気になります。</p>

                <h3>調子に乗って大型までとった</h3>
                    <p>最初は「車を守るため」という理由で自動二輪の免許をとったのですが、バイクに乗るのが割と楽しくなってきました。</p>
                    <p>こうなってくると「絶対次は大型に乗りたくなる」というのと「教習所に通い慣れてる今大型免許をとっておかないと、あとになってとるのは絶対に面倒になる」と思い、自動二輪取得の４ヶ月後に大型二輪までとってしまいました。</p>
                    <p>次はできれば大型に乗りたいですね。</p>
                    <p>ここまでが、私がバイクに乗り始めた経緯です。</p>
                
            <h2>バイクブログについて</h2>
                <h3>大分は結構ツーリング向けの道が多い</h3>
                    <p>バイクに乗って、地元大分県内をウロウロしながら思ったのが「結構ツーリングに適した道が多いなぁ」ということでした。</p>
                    <p>峠あり、綺麗な海岸線あり、気持ちのいい高原あり…ツーリング向けの場所が多いです。もちろん、そのような道は車でのドライブにもオススメです。</p>
                    <p>あと、温泉も有名なので、ツーリングで冷えた体を温泉で温める、という楽しみ方もできます。</p>
                    <p>大分は美味しい食べ物も多いですし「そのうちツーリングブログを立ち上げたいなぁ」と考えるようになりました。</p>
                    <p>パソコンも比較的得意なので、そのあたりもこのブログを立ち上げるモチベーションになっていきました。</p>
                
                <h3>自分の技術向上のためにブログを開設</h3>
                    <p>このあたりは、このブログの本筋から外れるので詳しくはこちらで。</p>
                    <p>ただ、自分の技術力を上げていきたい、という点と、ツーリングブログを立ち上げたい、という点が上手くマッチした感じです。</p>
                    <p>このブログは、Gatsbyというフレームワークを使って作っています。まったくバイクと関係ないのですが、結構ブログを作り込むのは大変です…。</p>
                    <p>ですが、結構ブログを作る感じが楽しいですし、このブログも、もっと改善していきたいですね。</p>
                    <p>もし何か要望がありましたら<Link to = {"/form"}>お問い合わせ</Link>から連絡いただけると嬉しいです。</p>
                
                <h3>このブログを読んでくれている皆様へ</h3>
                    <p>このブログを読んで、「大分行ってみてぇ！」とか「ツーリングの予定組もうと思ってるけど、大分の道、走ってみたいなぁ」とか思ってもらえたら、それが一番嬉しいです。</p>
                    <p>そう思っていただけるように努力していきますので、楽しんで読んでいただければと思います。</p>


            </div>
        </Layout>

    )
}
export default Profile